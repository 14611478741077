import React from "react";
import { navigate } from "gatsby";

import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text, Input } from "../components/Core";

import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

const FormStyled = styled.form``;

const WidgetWrapper = styled(Box)`
  border-radius: 10px;
  background-color: #f7f7fb;
  padding-top: 80px;
  padding-bottom: 30px;
  padding-left: 50px;
  padding-right: 50px;
  @media ${device.lg} {
    padding-left: 140px;
    padding-right: 150px;
  }
  @media ${device.xl} {
    padding-left: 150px;
    padding-right: 150px;
  }
`;

const defaultState = {
  name: "",
  email: "",
  message: "",
  sending: false,
};

class Contact2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
    };
  }

  clearForm() {}

  async handleFormSubmit(e) {
    e.preventDefault();
    const formdata = new FormData(e.target);
    this.setState({ sending: true });

    await this.postForm(formdata)
      .then(() => {
        navigate("/thank-you-contact");
      })
      .catch((error) => {
        console.log(error);
        this.setState({ ...defaultState });
      });
  }

  async postForm(formData) {
    // convert FormData to json object
    // SOURCE: https://stackoverflow.com/a/46774073
    const jsonData = JSON.stringify(Object.fromEntries(formData));

    // POST the request to API endpoint
    await fetch(
      "https://northctrlwebfunc.azurewebsites.net/api/WebSubmitContactForm",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: jsonData,
      }
    )
      .then((response) => {
        if (!response.ok) throw Error(response.status);
      })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log("error in postForm", error);
      });
  }

  handleChange = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { sending, name, email, message } = this.state;

    return (
      <>
        <PageWrapper footerDark>
          <Section>
            <div className="pt-5"></div>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <div className="banner-content">
                    <Title variant="hero">Contact us</Title>
                    <Text>
                      Fill out the form and we’ll be in touch as soon as
                      possible.
                    </Text>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col lg={6} className="order-lg-1 pt-0 mt-4">
                  <div>
                    <FormStyled
                      onSubmit={(e) => this.handleFormSubmit(e)}
                      action="/PleaseEnableJS"
                      method="POST"
                    >
                      <input
                        type="hidden"
                        name="form-name"
                        value={"loanerhub-contact"}
                      />
                      <Box mb={4}>
                        <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="name"
                        >
                          Your name
                        </Title>
                        <Input
                          onChange={this.handleChange}
                          value={name}
                          type="text"
                          placeholder="Name"
                          id="name"
                          name="name"
                        />
                      </Box>
                      <Box mb={4}>
                        <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="email"
                        >
                          Email address
                        </Title>
                        <Input
                          onChange={this.handleChange}
                          value={email}
                          name="email"
                          id="email"
                          type="email"
                          placeholder="Email"
                        />
                      </Box>

                      <Box mb={4}>
                        <Title
                          variant="card"
                          fontSize="18px"
                          as="label"
                          htmlFor="message"
                        >
                          Message
                        </Title>
                        <Input
                          type="text"
                          as="textarea"
                          placeholder="Write your message here"
                          rows={4}
                          onChange={this.handleChange}
                          value={message}
                          name="message"
                          id="message"
                        />
                      </Box>

                      <Button
                        disabled={sending || "" === email.trim()}
                        type="submit"
                        width="100%"
                        borderRadius={10}
                      >
                        {sending ? "Sending...." : "Send Message"}
                      </Button>
                    </FormStyled>
                  </div>
                </Col>
              </Row>
            </Container>
          </Section>
        </PageWrapper>
      </>
    );
  }
}
export default Contact2;
